<template>
  <div class="container myOrderDetail">
    <div class="back">
      <img src="@/assets/images/home/back@2x.png" alt @click="back()" />
      <!-- <span>我购买的课程</span> -->
    </div>
    <div class="status_name">
      <!-- <img :src="'@/assets/images/my/detail/'+orderDetail.orderStatus+'.png'" mode /> -->
      <img :src="statusImg(orderDetail.orderStatus)" mode />
      {{ orderDetail.orderStatus | orderStatusFilter }}
    </div>
    <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh()">
      <div class="theWrap">
        <div class="courseNameWrap">
          <div class="courseName">{{ orderDetail.courseName }}</div>
        </div>
        <div class="order_info">
          <div class="order_info_header">
            <img src="@/assets/images/my/user.png" mode />
            用户名
          </div>
          <div class="order_info_content">{{ orderDetail.nickname }}</div>
        </div>
        <div class="order_info">
          <div class="order_info_header">
            <img src="@/assets/images/my/wallet.png" mode />
            订单价格
          </div>
          <div class="order_info_content">¥{{ orderDetail.orderMoney }}</div>
        </div>
        <div class="order_info">
          <div class="order_info_header">
            <img src="@/assets/images/my/num.png" mode />
            订单号
          </div>
          <div class="order_info_content">{{ orderDetail.orderNumber }}</div>
        </div>
        <div class="order_info">
          <div class="order_info_header">
            <img src="@/assets/images/my/type.png" mode />
            支付方式
          </div>
          <div class="order_info_content">
            {{ orderDetail.payType | payTypeFilter }}
          </div>
        </div>
        <div class="order_info">
          <div class="order_info_header">
            <img src="@/assets/images/my/date.png" mode />
            支付时间
          </div>
          <div class="order_info_content">{{ orderDetail.payTime }}</div>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import _ from "lodash";
import { getDetail } from "@/api/my/myOrder";
import { Dialog, Toast } from "vant";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      refreshLoading: false,
      list: [],
      pages: {
        current: 0,
        size: 10,
      },
      loading: false,
      finished: false,
      orderDetail: {},
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    statusImg(state) {
      let img = "";
      switch (state) {
        case 1:
          img = require("@/assets/images/my/detail/1.png");
          break;
        // case 2:
        //   img = require('@/assets/images/my/detail/1.png')
        //   break;
        case 3:
          img = require("@/assets/images/my/detail/3.png");
          break;
        case 4:
          img = require("@/assets/images/my/detail/4.png");
      }
      return img;
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    toOrderDetails(cat) {
      this.$router.push({
        path: `/my/myOrder/detail`,
        query: { id: cat.id },
      });
    },
    liveMinute(s) {
      console.log(s);
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    // 获取列表
    async getDetail() {
      let postData = {
        id: this.theId,
      };
      const res = await getDetail(postData);
      this.orderDetail = res.data;
    },
    onRefresh() {
      this.pages.current = 1;
      this.getDetail();
      setTimeout(() => {
        this.refreshLoading = false;
      }, 500);
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initWXJSSDKNoShare()
    if (this.$route.query.id || this.$route.query.mid) {
      this.theId = this.$route.query.id || this.$route.query.mid;
      this.getDetail();
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.container.myOrderDetail {
  height: 100vh !important;
  background: #2298f4;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  .back {
    width: 100%;
    padding: 40px 20px 40px 20px;
    display: flex;
    background: #2298f4;
    position: relative;
    top: 0;
    left: 0;
    // height: 200px;
    z-index: 2;
    img {
      height: 45px;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
      margin-top: 8px;
    }
    span:last-child {
      margin: auto;
    }
  }
  .status_name {
    position: relative;
    padding: 30px;
    width: 100vw;
    font-weight: 600;
    font-size: 36px;
    color: #ffffff;
    line-height: 42px;
    display: flex;
    align-items: center;
  }
  .status_name img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
  .van-pull-refresh {
    padding: 30px;
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 225px);
    position: fixed;
    bottom: 0;
    border-radius: 30px 30px 0 0;
    background: #f7f7f7;
    z-index: 2;
  }
  .theWrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 30px 30px 0 0;
    /* margin-top: 20px; */
    /* padding-bottom: 100px; */

    .courseNameWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
    }
    .courseName {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 28px;
      color: #333333;
      line-height: 33px;
      text-align: left;
      font-style: normal;
      margin: 0 0 16px 0;
    }
    .stateName {
      font-weight: 400;
      font-size: 26px;
      line-height: 30px;
    }

    .stateName.color1 {
      color: #d5b87f;
    }

    .stateName.color3 {
      color: #2194ff;
    }

    .stateName.color4 {
      color: #999999;
    }

    .order_info {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    .order_info_header {
      width: 160px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 24px;
      color: #999999;
      line-height: 28px;
    }

    .order_info_header img {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }

    .order_info_content {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 28px;
    }
  }
}
</style>
<style lang="scss"></style>
